var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-12 col-md-6 col-lg-6" },
          [
            _c(
              "c-card",
              { attrs: { title: "검토 상세" } },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-tag", {
                        attrs: {
                          editable: _vm.editable,
                          disabled: _vm.disabled,
                          itemText: "label",
                          itemValue: "check",
                          label: "위원회",
                          name: "committees",
                        },
                        model: {
                          value: _vm.moc.committees,
                          callback: function ($$v) {
                            _vm.$set(_vm.moc, "committees", $$v)
                          },
                          expression: "moc.committees",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                    },
                    [
                      !_vm.emergencyCheck
                        ? [
                            _c("c-radio", {
                              attrs: {
                                disabled: _vm.disabled,
                                editable: _vm.editable,
                                comboItems: _vm.approvItems,
                                label: "승인여부",
                                name: "changeApprovalFlag",
                              },
                              model: {
                                value: _vm.moc.committeeHold.changeApprovalFlag,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.moc.committeeHold,
                                    "changeApprovalFlag",
                                    $$v
                                  )
                                },
                                expression:
                                  "moc.committeeHold.changeApprovalFlag",
                              },
                            }),
                          ]
                        : [
                            _c("c-radio", {
                              attrs: {
                                disabled: _vm.disabled,
                                editable: _vm.editable,
                                comboItems: _vm.keepItems,
                                label: "유지여부",
                                name: "changeKeepFlag",
                              },
                              model: {
                                value: _vm.moc.committeeHold.changeKeepFlag,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.moc.committeeHold,
                                    "changeKeepFlag",
                                    $$v
                                  )
                                },
                                expression: "moc.committeeHold.changeKeepFlag",
                              },
                            }),
                          ],
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                    },
                    [
                      _c("c-datepicker", {
                        attrs: {
                          disabled: _vm.disabled,
                          editable: _vm.editable,
                          label: !_vm.emergencyCheck
                            ? "승인/불승인 처리일"
                            : "유지/원복 결정일",
                          name: "changeApprovalDate",
                        },
                        model: {
                          value: _vm.moc.committeeHold.changeApprovalDate,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.moc.committeeHold,
                              "changeApprovalDate",
                              $$v
                            )
                          },
                          expression: "moc.committeeHold.changeApprovalDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      !_vm.emergencyCheck
                        ? [
                            _c("c-textarea", {
                              attrs: {
                                disabled: _vm.disabled,
                                editable: _vm.editable,
                                rows: 6,
                                label: "승인/불승인 사유",
                                name: "changeApprovalReason",
                              },
                              model: {
                                value:
                                  _vm.moc.committeeHold.changeApprovalReason,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.moc.committeeHold,
                                    "changeApprovalReason",
                                    $$v
                                  )
                                },
                                expression:
                                  "moc.committeeHold.changeApprovalReason",
                              },
                            }),
                          ]
                        : [
                            _c("c-textarea", {
                              attrs: {
                                disabled: _vm.disabled,
                                editable: _vm.editable,
                                rows: 6,
                                label: "변경유지/원복 사유",
                                name: "changeKeepReason",
                              },
                              model: {
                                value: _vm.moc.committeeHold.changeKeepReason,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.moc.committeeHold,
                                    "changeKeepReason",
                                    $$v
                                  )
                                },
                                expression:
                                  "moc.committeeHold.changeKeepReason",
                              },
                            }),
                          ],
                    ],
                    2
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-sm-12 col-md-6 col-lg-6" },
          [
            _c("c-table", {
              attrs: {
                title: "개최정보",
                columns: _vm.grid.columns,
                gridHeight: _vm.grid.height,
                data: _vm.grid.data,
                filtering: false,
                columnSetting: false,
                usePaging: false,
                editable: false,
              },
            }),
          ],
          1
        ),
      ]),
      _c("c-table", {
        staticClass: "q-mt-sm",
        attrs: {
          title: "검토필요항목 및 결과",
          columns: _vm.grid2.columns,
          gridHeight: _vm.grid2.height,
          data: _vm.moc.committeeReviewItems,
          filtering: false,
          usePaging: false,
          columnSetting: false,
          editable: false,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }