<template>
  <div>
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6">
        <c-card title="검토 상세">
          <template slot="card-detail">
            <div class="col-12">
              <c-tag 
                :editable="editable"
                :disabled="disabled"
                itemText="label"
                itemValue="check"
                label="위원회"
                name="committees" 
                v-model="moc.committees"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <template v-if="!emergencyCheck">
                <c-radio 
                  :disabled="disabled"
                  :editable="editable"
                  :comboItems="approvItems"
                  label="승인여부"
                  name="changeApprovalFlag" 
                  v-model="moc.committeeHold.changeApprovalFlag">
                </c-radio>
              </template>
              <template v-else>
                <c-radio 
                  :disabled="disabled"
                  :editable="editable"
                  :comboItems="keepItems"
                  label="유지여부"
                  name="changeKeepFlag" 
                  v-model="moc.committeeHold.changeKeepFlag">
                </c-radio>
              </template>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <c-datepicker
                :disabled="disabled"
                :editable="editable"
                :label="!emergencyCheck ? '승인/불승인 처리일': '유지/원복 결정일'"
                name="changeApprovalDate"
                v-model="moc.committeeHold.changeApprovalDate"
              />
            </div>
            <div class="col-12">
              <template v-if="!emergencyCheck">
                <c-textarea
                  :disabled="disabled"
                  :editable="editable"
                  :rows="6"
                  label="승인/불승인 사유"
                  name="changeApprovalReason" 
                  v-model="moc.committeeHold.changeApprovalReason">
                </c-textarea>
              </template>
              <template v-else>
                <c-textarea
                  :disabled="disabled"
                  :editable="editable"
                  :rows="6"
                  label="변경유지/원복 사유"
                  name="changeKeepReason" 
                  v-model="moc.committeeHold.changeKeepReason">
                </c-textarea>
              </template>
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6">
        <c-table
          title="개최정보"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :editable="false"
        >
        </c-table>
      </div>
    </div>
    <c-table
      class="q-mt-sm"
      title="검토필요항목 및 결과"
      :columns="grid2.columns"
      :gridHeight="grid2.height"
      :data="moc.committeeReviewItems"
      :filtering="false"
      :usePaging="false"
      :columnSetting="false"
      :editable="false"
    >
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'moc-meeting-tl',
  props: {
    param: {
      type: Object,
      default: () => ({
        sopMocId: '',
        mocTypeCd: '',
        mocStepCd: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: function() {
        return false;
      },
    },
  },
  data() {
    return {
      moc: {
        sopMocId: '',  // MOC 일련번호
        mocNo: '',  // moc 관리번호
        plantCd: '',  // 사업장 코드
        initiativeUserId: '',  // 발의자 ID
        initiativeUserName: '',  // 발의자 명
        initiativeDeptCd: '',  // 발의 부서 코드
        initiativeDeptName: '',  // 발의 부서명
        mocTitle: '',  // 변경관리 제목
        mocSummary: '',  // 변경 개요
        mocTypeCd: 'MT00000001',  // 변경구분_공통코드(정상/비상/임시)
        mocWriteLevelCd: null,  // 변경등급_작성
        mocConfirmLevelCd: null,  // 변경등급_검토
        processSatefySecureMeasures: '',  // 공정안전 확보 대책
        technicalBasisProcessDesign: '',  // 변경계획에 대한 공정 및 설계의 기술근거
        safetyReliabilityImprovementEffect: '',  // 안전성에 필요한 사항 및 신뢰성 향상 효과
        leaderOpinion: '',  // 팀장의견
        relatedLaws: '',  // 관련 볍령_텍스트
        processRiskAssessFlag: '',  // 공정위험성 평가 여부
        jobRiskAssessFlag: '',  // 작업위헝성 평가 여부
        mocPeriod: [],
        mocStartDate: '',  // 변경 시작일
        mocEndDate: '',  // 변경 종료일
        mocStepCd: '',  // 변경관리 단계
        mocActionDeptCd: '',  // 변경실행 부서코드
        mocActionResponsibilityUserId: '',  // 변경실행 책임자 ID
        mocCompleteCheckUserId: '',  // 변경완료 확인자 ID
        mocCompleteCheckDeptCd: '',  // 변경완료 확인 부서 코드
        mocCompleteOpinion: '',  // 변경완료 의견
        mocBenefitCds: '',  // 변경관리 기대효과_복수
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        checklists: [],
        checklistEquips: [],
        checklistDrives: [],
        equipments: [],
        reviews: [],
        committees: [],
        committeeReviewItems: [],
        committeeHold: {
          sopMocId: '',  // MOC 일련번호
          firstHoldDate: '',  // (최초) 개최일
          firstHoldArea: '',  // (최초) 개최장소
          firstReviewContents: '',  // (최초) 검토내용
          firstReviewResult: '',  // (최초) 검토결과
          professionalHoldDate: '',  // (전문가) 개최일
          professionalHoldArea: '',  // (전문가) 개최장소
          professionalReviewContents: '',  // (전문가) 검토내용
          professionalReviewResult: '',  // (전문가) 검토결과
          finalHoldDate: '',  // (최종) 개최일
          finalHoldArea: '',  // (최종) 개최장소
          finalReviewContents: '',  // (최종) 검토내용
          finalReviewResult: '',  // (최종) 검토결과
          changeApprovalFlag: '',  // 변경 승인 여부
          changeApprovalReason: '',  // 변경 승인 사유
          changeApprovalDate: '',  // 승인일자
          changeKeepFlag: '',  // 변경유지 여부
          changeKeepReason: '',  // 변경유지 사유
          regUserId: '',  // 등록자 ID
          chgUserId: '',  // 수정자 ID
        },
        relatedWorks: [],
        prestartupImproves: [],
      },
      grid: {
        columns: [
          {
            name: 'gubun',
            field: 'gubun',
            label: '구분',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'holdDate',
            field: 'holdDate',
            label: '개최일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'holdArea',
            field: 'holdArea',
            label: '개최장소',
            align: 'left',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'reviewContents',
            field: 'reviewContents',
            label: '검토내용',
            align: 'left',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'reviewResult',
            field: 'reviewResult',
            label: '검토결과',
            align: 'left',
            style: 'width:150px',
            sortable: true,
          },
        ],
        data: [],
        height: '350px'
      },
      grid2: {
        columns: [
          {
            name: 'reviewItemName',
            field: 'reviewItemName',
            label: '검토항목',
            align: 'left',
            style: 'width:250px',
            sortable: true,
          },
          {
            name: 'confirmUserName',
            field: 'confirmUserName',
            label: '확인자',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'reviewCompleteRequestDate',
            field: 'reviewCompleteRequestDate',
            label: '검토완료요구일자',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'reviewUserName',
            field: 'reviewUserName',
            label: '검토자',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'reviewContents',
            field: 'reviewContents',
            label: '검토내용',
            align: 'left',
            style: 'width:400px',
            sortable: true,
          },
          {
            name: 'reviewCompleteDate',
            field: 'reviewCompleteDate',
            label: '검토완료일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'col5',
            field: 'col5',
            label: '첨부',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
        ],
        height: '400px'
      },
      approvItems: [
        { code: 'Y', codeName: '승인' },
        { code: 'N', codeName: '불승인' },
      ],
      keepItems: [
        { code: 'Y', codeName: '유지' },
        { code: 'N', codeName: '원복' },
      ],
      editable: true,
      detailUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    emergencyCheck() {
      return this.param.mocTypeCd === 'MT00000010'
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.moc.change.review.get.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.param.sopMocId) {
        this.$http.url = this.$format(this.detailUrl, this.param.sopMocId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.moc, _result.data);
          if (!this.moc.committeeHold) {
            this.$set(this.moc, 'committeeHold', {
              sopMocId: '',  // MOC 일련번호
              firstHoldDate: '',  // (최초) 개최일
              firstHoldArea: '',  // (최초) 개최장소
              firstReviewContents: '',  // (최초) 검토내용
              firstReviewResult: '',  // (최초) 검토결과
              professionalHoldDate: '',  // (전문가) 개최일
              professionalHoldArea: '',  // (전문가) 개최장소
              professionalReviewContents: '',  // (전문가) 검토내용
              professionalReviewResult: '',  // (전문가) 검토결과
              finalHoldDate: '',  // (최종) 개최일
              finalHoldArea: '',  // (최종) 개최장소
              finalReviewContents: '',  // (최종) 검토내용
              finalReviewResult: '',  // (최종) 검토결과
              changeApprovalFlag: '',  // 변경 승인 여부
              changeApprovalReason: '',  // 변경 승인 사유
              changeApprovalDate: '',  // 승인일자
              changeKeepFlag: '',  // 변경유지 여부
              changeKeepReason: '',  // 변경유지 사유
              regUserId: '',  // 등록자 ID
              chgUserId: '',  // 수정자 ID
            })
          }
          if (this.moc.committees && this.moc.committees.length > 0) {
            this.$_.forEach(this.moc.committees, committee => {
              this.$set(committee, 'label', committee.mocRoleName + '/' + committee.userName + (committee.deptName ? ('/' + committee.deptName) : ''))
              this.$set(committee, 'check', true)
            })
          }
          this.grid.data.push({
            gubun: '최초',
            holdDate: this.moc.committeeHold.firstHoldDate,
            holdArea: this.moc.committeeHold.firstHoldArea,
            reviewContents: this.moc.committeeHold.firstReviewContents,
            reviewResult: this.moc.committeeHold.firstReviewResult,
          })
          this.grid.data.push({
            gubun: '전문가',
            holdDate: this.moc.committeeHold.professionalHoldDate,
            holdArea: this.moc.committeeHold.professionalHoldArea,
            reviewContents: this.moc.committeeHold.professionalReviewContents,
            reviewResult: this.moc.committeeHold.professionalReviewResult,
          })
          this.grid.data.push({
            gubun: '최종',
            holdDate: this.moc.committeeHold.finalHoldDate,
            holdArea: this.moc.committeeHold.finalHoldArea,
            reviewContents: this.moc.committeeHold.finalReviewContents,
            reviewResult: this.moc.committeeHold.finalReviewResult,
          })
        },);
      }
    },
  }
};
</script>
